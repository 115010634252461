export const jobList = [
  {
    title: "ITA Front-End Web Developer",
    company: "California Commission on Teacher Credentialing",
    dates: "March 2024 - Present",
    description: [
      "Worked as a member of the Web Team to administer daily maintenance and enhancements to the Commission’s public website and internal SharePoint sites.",
      "Provided web accessibility guidance and assistance to Commission staff and ensure all public web pages and documents meet ADA standards.",
    ],
  },
  {
    title: "Full-Stack Software Developer Intern",
    company: "Positon",
    dates: "Feb. 2023 - August 2023",
    description: [
      "Built 6+ new features, encompassing both front-end UI and back-end database integration",
      "Created and implemented over 30 custom hooks and 11 custom components to enhance application functionality",
      "Assisted in advancing the front-end and back-end stack to improve overall system performance and usability",
    ],
  },
  {
    title: "Lead Frontend Developer",
    company: "SpaceLab",
    dates: "Oct. 2022 - August 2023",
    description: [
      "Led the architecture of 5+ new features, and 11+ reusable components, to improve application functionality",
      "Oversaw the development and maintenance of new products, technical documentation, and workflow processes to ensure quality and efficiency",
      "Collaborated cross-functionally with 5 different teams, including engineers and designers, to ensure timely delivery of high-quality products",
    ],
  },
  {
    title: "Web Developer",
    company: "PV Magazine",
    dates: "Aug. 2022 - August 2023",
    description: [
      "Design and implement strategies for web growth including website design, Search Engine Optimization, marketing",
      "Implement web performance optimizations",
      "Create and design email marketing campaigns for an audience of over 1,200 readers",
    ],
  },
  {
    title: "Contract Web Developer",
    company: "EmpoweredU",
    dates: "Jan. 2021 - Mar. 2021",
    description: [
      "Designed and built the EmpoweredU website in WordPress to ensure accessibility for non tech-savvy contributors",
      "Worked directly with the client to develop a visually pleasing, responsive, and user-friendly website according to their specifications",
    ],
  },
  {
    title: "Computer Systems & Assembly Tutor",
    company: "Univ. of California",
    dates: "Sept. 2019 - Dec. 2021",
    description: [
      "Educated more than 1,000 undergraduate students on computer fundamentals e.g. logic, design, memory, assembly language, and debugging techniques",
      "Organized and conducted weekly tutoring sessions that focused on reviewing class concepts, explicating assignments, and assisting with debugging",
    ],
  },
  {
    title: "Tech Writing for Engineers Grader",
    company: "Univ. of California",
    dates: "Sept. 2021 - Mar. 2022",
    description: [
      "Provided feedback on assignments, advising students on effective written and verbal communication and professional presentation ",
    ],
  },
  {
    title: "Introduction to 3D Animation Tutor",
    company: "Univ. of California",
    dates: "Sept. 2020 - Dec. 2020",
    description: [
      "Educated students on 3D Animation concepts and using Blender to create assets, animations, textures, and effects",
    ],
  },
];
