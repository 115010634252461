/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import React from "react";
import { useGLTF, useVideoTexture } from "@react-three/drei";
import glb from "./ComputerSimple.glb";
import codeVid from "./Code_small.mp4";

const DesktopComputer = (props) => {
  const { nodes, materials } = useGLTF(glb);
  const video = useVideoTexture(codeVid, { muted: true, loop: true });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Laptop.geometry}
        material={materials.Gray_Metal}
        position={[-0.3, -0.21, -0.11]}
        rotation={[0, 0, -1.58]}
        scale={[0.03, 0.54, 0.97]}
      />
      <mesh
        geometry={nodes.Screen.geometry}
        material={materials["Black_Metal.001"]}
        position={[-0.82, 0.45, -0.11]}
        scale={[0.01, 0.55, 0.93]}
      >
        <meshBasicMaterial map={video} toneMapped={true} map-flipY={false} />
      </mesh>
      <group
        position={[-0.7, -0.16, 0.65]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0, 0.06, 0.07]}
      >
        <mesh geometry={nodes.Cube001.geometry} material={materials.Keys} />
        <mesh
          geometry={nodes.Cube001_1.geometry}
          material={materials["Keys.001"]}
        />
      </group>
    </group>
  );
};

export default DesktopComputer;

useGLTF.preload(glb);
